<template>
  <v-dialog
    v-model="localValue"
    max-width="600"
  >
    <v-card class="elevation-12">
      <v-toolbar color="info">
        <v-toolbar-title>Do you want to delete this entry?</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
       </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="info" @click="confirmed">Delete</v-btn>
        <v-btn text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'DeleteConfirmationPopup',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (localValue) {
        this.$emit('input', localValue)
      }
    },
  },
  methods: {
    close () {
      this.localValue = false
    },
    confirmed () {
      this.close()
      this.$emit('confirmed')
    }
  }
}
</script>
