<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        DOIs
        <v-btn
          class="ml-2"
          fab
          x-small
          color="primary"
          :disabled="!loggedIn"
          @click="createDoi"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <create-doi-variant-choice-popup v-model="createDoiVariantChoicePopupVisible"/>
        <delete-confirmation-popup v-model="deleteDoiConfirmationPopupVisible" @confirmed="deleteDoiConfirmed"/>
        <v-skeleton-loader
          v-if="!items"
          type="table-thead table-tbody"
        />
        <v-data-table
          v-else
          :headers="headers"
          :items="items"
          item-key="doi"
          :options.sync="paging"
          :server-items-length="totalNumberOfDois"
          :loading="loading"
          @update:items-per-page="loadDois"
          @update:page="loadDois"
          @dblclick:row="editDoi"
        >
          <template #item.link="{ item }">
            <a :href="createDoiUrl(item.doi)" target="_blank">
              {{ item.doi }}
            </a>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              :disabled="!item.isActionable"
              class="mr-2"
              color="primary"
              @click="editDoi(undefined,{ item })"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              :disabled="!item.isActionable"
              color="error"
              @click="deleteDoi(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import environment from '@/shared/utils/environment'
import loading from '@/shared/models/loading'
import account from '@/shared/models/account'
import backendServices from '@/shared/services/backend-services'
import { CREATE_DOI_ROUTE } from '@/router/route-names'
import CreateDoiVariantChoicePopup from '@/shared/popups/create-doi-variant-choice-popup'
import DeleteConfirmationPopup from '@/shared/popups/delete-confirmation-popup'
import BaseComponent from '@/shared/components/base-component'

const HEADERS = [
  {
    text: 'DOI',
    align: 'start',
    sortable: false,
    value: 'link',
    width: '15%',
  },
  {
    text: 'Title',
    sortable: false,
    value: 'title',
    width: '50%',
  },
  {
    text: 'Status',
    sortable: false,
    value: 'state',
  },
  {
    text: 'Created by',
    sortable: false,
    value: 'createdByName',
  },
  {
    text: 'Authored by',
    sortable: false,
    value: 'doiAuthors',
    width: '10%',
  },
  {
    text: 'Approved by',
    sortable: false,
    value: 'assignedToName',
  },
  {
    text: 'Actions',
    sortable: false,
    value: 'actions'
  }
]

export default {
  name: 'ListDoiView',
  extends: BaseComponent,
  components: { CreateDoiVariantChoicePopup, DeleteConfirmationPopup },
  props: {
    code: { // if set this is a authorization code
      type: String,
      required: false
    }
  },
  data () {
    return {
      deleteDoiConfirmationPopupVisible: false,
      createDoiVariantChoicePopupVisible: false,
      itemToDelete: undefined,
      items: undefined,
      totalNumberOfDois: undefined,
      paging: {
        page: 1,
        itemsPerPage: 15,
        pagination: {
          rowsPerPageText: 'DOIs per page', // Customize the text here
          rowsPerPageItems: [10, 20, 30, -1], // Example: [-1, 10, 20, 30]
        },
      }
    }
  },
  computed: {
    loggedIn () {
      return account.loggedIn
    },
    loading () {
      return loading.isLoading
    }
  },
  watch: {
    loggedIn () {
      this.loadDois()
    }
  },
  async created () {
    this.headers = HEADERS
    // login using code given by redirect from authorization page
    if (this.code && !this.loggedIn) {
      loading.details = 'Logging you in'
      loading.startLoading()
      await account.login(this.code)
      loading.stopLoading()
      // get rid of code parameter in url
      window.history.replaceState({}, document.title, '/')
    }
    this.loadDois()
  },
  methods: {
    createDoiUrl (doi) {
      return `${environment.landingPageBaseUrl}${encodeURIComponent(`${environment.apiUrl}/api/dois/${encodeURIComponent(doi)}`)}`
    },
    async loadDois () {
      const params = {
        state: 'draft,findable',
        'page[size]': this.paging.itemsPerPage,
        'page[number]': this.paging.page,
        sort: '-created',
        prefix: 10.34731
      }
      const { data: result } = await this.catchError(backendServices.getDois(params))
      this.totalNumberOfDois = result.meta.total
      const doiData = await Promise.all(result.data.map(async item => {
        const doiAuthors = await this.getAuthors(item.doi)
        const doiAuthorsArray = doiAuthors ? doiAuthors.split('; ').map(s => s.trim().toLowerCase()) : []
        const userNormalized = account.username ? account.username.trim().toLowerCase() : ''
        const matchingAuthor = doiAuthorsArray.find(doiAuthor => doiAuthor === userNormalized)
        const isAuthorUserName = doiAuthorsArray.includes(userNormalized) // Correctly determines if the user is a author
        const isUserAuthorOrInvolved = isAuthorUserName || account?.userId === item?.assignedTo || account?.userId === item?.createdBy // removed &&!item?.assignedTo
        return {
          ...item,
          doiAuthors,
          matchingAuthor,
          isActionable: this.loggedIn && isUserAuthorOrInvolved
        }
      }))
      this.items = doiData
    },
    async getAuthors (doi) {
      try {
        const response = await backendServices.getDoi(String(doi))
        const doiData = response.data
        if (doiData && doiData.data.attributes && doiData.data.attributes.creators && doiData.data.attributes.creators.length > 0) {
          const doiAuthors = doiData.data.attributes.creators.map(creator => {
            // Assuming each creator might have multiple identifiers, find the mailto one, to split from the email ids
            const mailIdentifier = creator.nameIdentifiers.find(identifier =>
              identifier.nameIdentifierScheme === 'mailto'
            )
            if (mailIdentifier) {
              // Split the identifier at '@'
              const AuthUser = mailIdentifier.nameIdentifier.split('@')[0].trim().toLowerCase()
              return AuthUser
            }
            return null
          }).filter(identifier => identifier !== null) // Filter out any null values (creators without a mailto identifier)
          return doiAuthors.join('; ')
        } else {
          return null // Return null when there are no co-authors
        }
      } catch (error) {
        return null
      }
    },
    closeDeleteDoiConfirmationPopup () {
      this.deleteDoiConfirmationPopupVisible = false
    },
    deleteDoi (item) {
      this.itemToDelete = item
      this.deleteDoiConfirmationPopupVisible = true
    },
    async deleteDoiConfirmed () {
      this.closeDeleteDoiConfirmationPopup()
      this.catchError(backendServices.deleteDoi(this.itemToDelete.doi))
      // delete doi also in table, avoiding to get dois via api
      this.items = this.items.filter(item => this.itemToDelete.doi !== item.doi)
      this.paging.page = 1
      this.itemToDelete = undefined
    },
    createDoi () {
      this.createDoiVariantChoicePopupVisible = true
    },
    async editDoi (event, { item: { doi, isActionable } }) {
      isActionable && this.$router.push({ name: CREATE_DOI_ROUTE, query: { selectedDoi: doi } })
    }
  }
}
</script>
