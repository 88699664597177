<template>
  <div>
    <v-dialog
      v-model="localValue"
      max-width="600"
      persistent
    >
      <v-card class="elevation-12">
        <v-toolbar color="info">
          <v-toolbar-title>Create DOI</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-radio-group v-model="type" row>
            <v-radio v-for="[value, { label }] of Object.entries(types)" :key="value" :label="label" :value="value"/>
          </v-radio-group>
          <v-text-field label="Handle/URL" v-model="url" :rules="urlHandleRules" :disabled="type === 'manual'" >
            <template v-slot:append v-if="type !== 'manual'">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                    <v-icon @click.prevent="openLink" v-on="on" >mdi-help-circle</v-icon>
                  </template>
                <span>
                  Enter from geonetwork or BUTT
                  <br>
                  i.e. File identifier: 1a6ee651-2cd0-35f9-b3c2-38e206d585ca
                </span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="info" @click="process">Apply</v-btn>
          <v-btn text @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="errorSnackbar" color="error" top>{{ errorMessage }}</v-snackbar>
  </div>
</template>

<script>
import { CREATE_DOI_ROUTE } from '@/router/route-names'
import * as rules from '@/shared/components/rules'

const TYPE_VALUE_MANUAL = 'manual'
const TYPE_VALUE_METADATA_HANDLE = 'metadata-handle'

const TYPES = {
  [TYPE_VALUE_MANUAL]: { label: 'Manually', query: () => ({}) },
  [TYPE_VALUE_METADATA_HANDLE]: { label: 'Metadata file identifier(Handle)/URL', query: url => ({ query: { identifier: url } }) }
}

export default {
  name: 'CreateDoiVariantChoicePopup',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      type: 'manual',
      url: '',
      handle: '',
      urlHandleRules: [rules.requiredRule, rules.HandleOrUrlRule],
      errorSnackbar: false,
      errorMessage: ''
    }
  },
  watch: {
    type (selectedType) {
      if (selectedType === 'manual') {
        this.url = ''
        this.urlHandleRules = []
      }
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (localValue) {
        this.$emit('input', localValue)
      }
    },
  },
  async created () {
    this.types = TYPES
  },
  methods: {
    openLink () {
      window.open('https://teodoor.icg.kfa-juelich.de/geonetwork/', '_blank')
    },
    close () {
      this.localValue = false
    },
    async process () {
      if (this.type === 'manual') {
        this.$router.push({ name: CREATE_DOI_ROUTE, ...(TYPES[this.type].query()) })
        return
      }
      if (!this.url.trim()) {
        this.errorMessage = 'Metdata file identifier/URL cannot be empty. Please enter a valid Handle/URL'
        this.errorSnackbar = true
        return
      }
      if (!this.isValidUrl(this.url)) {
        this.errorMessage = 'Invalid input. Please use a valid metadata file identifer or URL.'
        this.errorSnackbar = true
        return
      }
      this.$router.push({ name: CREATE_DOI_ROUTE, ...(TYPES[this.type].query(this.url)) })
      this.close()
    },
    isValidUrl (url) {
      const isFileIdentifierWithHyphen = /^[a-f\d]{8}-([a-f\d]{4}-){3}[a-f\d]{12}$/i.test(url)
      const isFileIdentifierWithoutHyphen = /^[a-f\d]{40}$/i.test(url)
      if (isFileIdentifierWithHyphen || isFileIdentifierWithoutHyphen) {
        return rules.HandleOrUrlRule(url)
      } else {
        const isValidPrefix = url.startsWith('https://teodoor.icg.kfa-juelich.de') || url.startsWith('https://hdl.handle.net')
        if (!isValidPrefix) {
          console.error('Invalid URL prefix', url)
          return false
        }
        return rules.HandleOrUrlRule(url)
      }
    }
  }
}
</script>
